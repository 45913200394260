import React, { useState } from 'react';
import tw from 'twin.macro';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}
const initialState = {
  name: '',
  email: '',
  text: `Hallo,
Ich interessiere mich für eine Reise. 
Bitte bitte melden Sie sich bei mir über die angegebene E-Mailadresse!`,
};

export default function Kontakt() {
  const [data, setData] = useState(initialState);

  const handleChange = ({ target: { value, name } }) =>
    setData(c => ({ ...c, [name]: value }));

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...data,
      }),
    })
      .then(() => {
        setData(initialState);
      })
      .catch(error => alert(error));
  };
  return (
    <Layout>
      <SEO title="Kontakt" />
      <div css={tw`px-6 my-6 sm:my-16 xl:px-48`}>
        <h1
          className="underlined big"
          css={tw`mb-6 font-serif text-3xl sm:text-5xl`}
        >
          Kontaktieren Sie uns:
        </h1>
        <p css={tw`prose-lg sm:prose-xl`}>
          Auf dem Postweg erreichen Sie uns unter der Adresse:
          <br />
          <br />
          <b>Fitnessreisen-Rahr</b>
          <br />
          Kegelswies 43 <br />
          D-53773 Hennef
          <br />
          <br />
          Telefonisch oder per E-Mail erreichen Sie uns so:
          <br />
          <b>Tel.:</b> <a href="tel: 004915115301656">+49 (0) 151/15301656</a>{' '}
          <br />
          <b>WhatsApp:</b>{' '}
          <button
            type="button"
            onClick={() => {
              window.open(`https://api.whatsapp.com/send?phone=+4915254039933`);
            }}
          >
            +49 (0) 152/54039933
          </button>
          <br />
          <b>E-Mail:</b>{' '}
          <a href="mailto:info@fitnessreisen-online.de">
            info@fitnessreisen-online.de
          </a>
        </p>
        <hr css={tw`max-w-5xl my-6 `} />
        <h2 css={tw`mb-6 font-serif text-2xl sm:text-4xl`}>
          Oder nutzen sie folgendes Formular:
        </h2>
        <form
          css={tw`max-w-2xl text-lg`}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label htmlFor="bot-field">
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <label htmlFor="name">
            Name:
            <div>
              <input
                css={tw`block w-full p-1 mt-1 mb-6 bg-gray-100 border-transparent rounded-md outline-none focus:border-gray-500`}
                type="text"
                name="name"
                required
                onChange={handleChange}
                value={data.name}
              />
            </div>
          </label>
          <label htmlFor="email">
            E-Mail:
            <input
              css={tw`block w-full p-1 mt-1 mb-6 bg-gray-100 border-transparent rounded-md outline-none focus:border-gray-500`}
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={data.email}
            />
          </label>
          <label htmlFor="text">
            Ihr Anliegen:
            <textarea
              css={tw`block w-full p-2 mt-1 bg-gray-100 border-transparent rounded-md outline-none focus:border-gray-500`}
              required
              name="text"
              onChange={handleChange}
              value={data.text}
              rows={6}
            />
          </label>
          <div>
            <button
              css={tw`px-4 py-2 mt-8 text-xl font-medium rounded-md bg-brand-orange hover:bg-brand-darkOrange`}
              type="submit"
            >
              Absenden
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}
